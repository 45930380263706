import React, { useState, useEffect,useContext } from 'react'
import { UserContext } from '../context/UserContext'
import { IOrganization } from '../Interfaces/IOrganization'
import { ISite } from '../Interfaces/ISite'
import { useNavigate } from 'react-router-dom'
import { FaArrowRight } from 'react-icons/fa';
import { Card, CardContent, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import Cookies from 'js-cookie';
const Org_Site_Listpage = () => {
    const {authTokens,logoutUser,changeSite,user,Chatbot,changeOrgId} = useContext(UserContext)
    const [orgs, setorgs] = useState<IOrganization[]>([])
    const [selectedOrgId,setselectedOrgId] = useState<number>(0)
    const [sites, setsites] = useState<ISite[]>([])
    const navigate = useNavigate()
    const [openSiteDialog, setOpenSiteDialog] = useState(false);
    const [siteName, setSiteName] = useState('');
    const [siteId, setSiteId] = useState('');
    const [metaFile, setMetaFile] = useState<string>('');
    const [error, setError] = useState('');
    const csrfToken = Cookies.get('csrftoken');
    useEffect(() => {
        getOrgs()
        
    }, [])
    
    //////////////////
    const handleAddOrg = (event:any) => {
        console.log('handleAddOrg')
    }
    const handleAddSite = (event:any) => {
        console.log('handleAddSite')
    }
    const [open, setOpen] = useState(false);
    const [newOrgName, setNewOrgName] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        getOrgs()
    };

    const handleChange = (e:any) => {
        setNewOrgName(e.target.value);
    };

    const handleSubmit = async () => {
        
        const headers = new Headers();
          headers.append('Content-Type', 'application/json',);
          headers.append('X-CSRFToken', csrfToken || '');
        const newOrg = { name: newOrgName };
        console.log(user)
        const data = {
            name: newOrg.name,
            user_id: user.user_id
        };
        const url = 'api/add_organizations/'
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          handleClose()  
        }
    

    const handleOpenSiteDialog = () => {
        setOpenSiteDialog(true);
    };
    
    const handleCloseSiteDialog = () => {
    setOpenSiteDialog(false);
    setSiteName('');
    setSiteId('');
    setselectedOrgId(0);
    setMetaFile('');
  };
  const handleChangeSite = (e:any) => {
    const { name, value } = e.target;
    if (name === 'siteName') setSiteName(value);
    if (name === 'siteId') setSiteId(value);
    if (name === 'organization') setselectedOrgId(value);
    if (name === 'meta') setMetaFile(value || "");
  };


  
  const handleSubmitSite = async () => {
    const formData = new FormData();
    formData.append('name', siteName);
    formData.append('site_id', siteId);
    formData.append('organization', selectedOrgId.toString());
    formData.append('meta_file', metaFile);
    
    try {
        const headers = new Headers();
          //headers.append('Content-Type', 'application/json',);
          headers.append('X-CSRFToken', csrfToken || '');
          
        const response = await fetch('api/add_site/', {
            method: 'POST',
            headers: headers,
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        console.log('Site created:', responseData);
        handleCloseSiteDialog();
        } catch (error:any) {
        setError('Failed to communicate with the server');
        console.error('Error:', error.message);
        }
    };
    ////////////////////
    const handleSiteChange = (event:any) => {
        if(changeSite)
        {
            changeSite(parseInt(event.target.value))

        }
        Chatbot()
        navigate('/chat')
    }

    const handleOrgChange = (event:any) => {
        const selectedValue = event.target.value;
        
        
        if(changeOrgId)
            changeOrgId(selectedValue);
            getSiteList(selectedValue);
            setselectedOrgId(selectedValue);
        
      };
    const getOrgs = async () => {

        const response = await fetch('api/org_list/',
        {  
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization':`Bearer ${authTokens.access}`
            }
        })
        const data = await response.json()
        if (response.status === 200){
            setorgs(data)
            
        }else if(response.statusText === 'Unauthorized'){
            logoutUser()
        }
        
    }

    const getSiteList = async (selectedOrgId:number) => {
        const response = await fetch('api/org_list/'+selectedOrgId+'/',
        {  
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization':`Bearer ${authTokens.access}`
            }
        })
        const data = await response.json()
        if (response.status === 200){
            setsites(data)
            
            
        }else{
            console.error("Error occured")
        }
    }
    
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    
    let heading_size = "h5"
    
    const getCardWidth = () => {
        if (screenWidth >= 1024) {
          return Math.max(400, screenWidth * 0.9); 
        } else if (screenWidth >= 768) {
          return Math.max(400, screenWidth * 0.9); 
        } else if(screenWidth > 280) {
          return Math.max(400, screenWidth * 0.9); 
        }
        else{
            return Math.max(100, screenWidth * 0.9);
        }
      };
      const cardWidth = getCardWidth();
    
      useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
        <div>
            <div style={{ padding: '20px', marginTop:'30px', marginLeft:'40px' }}>
                {/* Organization List */}
                <Card style={{
            flex: '1 1 50%',
            minWidth: '30vh',
            margin: '10px',
            minHeight: 'auto',
            background: 'None',
            borderRadius: '20px',
            maxHeight: '45vh',
            maxWidth: `${cardWidth}px`,
            marginTop:'40px' 
          }}>
                    <CardContent>
                    <Typography variant={heading_size as any} component="div" className='org_list_header'>
                    ORGANIZATION LIST
                    </Typography>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleOpen}
                        style={{ margin: '10px 0' }}
                    >
                        Add Organization
                    </Button>
                    <ul  className='org_custom_list' style={{ 
                      maxHeight: '30vh',  /* Set a max height for the list */
                      overflowY: 'auto',   /* Enable vertical scrolling */
                      padding: '10px',     /* Optional: Add padding for better look */
                      margin: '0',         /* Remove default margin */
                      listStyle: 'none'    /* Optional: Remove bullet points */
                    }}>
                    {orgs.map((org,index) => (
                    <li key={index} value={org.id} className='org_list' onClick={handleOrgChange}>
                        {org.name}
                        <FaArrowRight className='arrow-icon'></FaArrowRight>
                    </li>
                    ))}
                    </ul>
                    </CardContent>
                </Card>
                <Card style={{ flex: '1 1 50%',
                    minWidth: '30vh',
                    margin: '10px',
                    minHeight: 'auto',
                    maxHeight: '35vh',
                    background: 'None',
                    borderRadius: '20px',
                    maxWidth: `${cardWidth}px`, marginTop:'30px' }}>
                    <CardContent>
                    <Typography variant={heading_size as any} component="div" className='site_list_header'>
                        SITE LIST
                    </Typography>
                    {selectedOrgId !== 0 && (
                      <Button variant="contained" color="primary" onClick={handleOpenSiteDialog} style={{ margin: '10px 0' }}>
                        Add Site
                      </Button>
                    )}
                    {sites.length > 0 ? (
                    <ul className='site_custom_list'style={{ 
                      maxHeight: '30vh',  /* Set a max height for the list */
                      overflowY: 'auto',   /* Enable vertical scrolling */
                      padding: '10px',     /* Optional: Add padding for better look */
                      margin: '0',         /* Remove default margin */
                      listStyle: 'none'    /* Optional: Remove bullet points */
                    }} >
                    {sites.map((site,index) => (
                    <li key={index} value={site.site_id} className='org_list' onClick={handleSiteChange}>
                        
                        {site.name} 
                        <FaArrowRight className='arrow-icon'></FaArrowRight>
                        
                    </li>
                ))}
                    </ul>
                    ) : (
                        <Typography variant="h6">No items available</Typography>
                      )}
                    </CardContent>
                </Card>
            </div>
            <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Organization</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the name of the new organization.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Organization Name"
            type="text"
            fullWidth
            value={newOrgName}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>


      {/* Site Dialog */}
      <Dialog open={openSiteDialog} onClose={handleCloseSiteDialog}>
        <DialogTitle>Add New Site</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter the details of the new site.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="siteName"
            label="Site Name"
            type="text"
            fullWidth
            value={siteName}
            onChange={handleChangeSite}
          />
          <TextField
            margin="dense"
            name="siteId"
            label="Site ID"
            type="text"
            fullWidth
            value={siteId}
            onChange={handleChangeSite}
          />
          <TextField
            select
            margin="dense"
            name="organization"
            label="Organization"
            fullWidth
            value={selectedOrgId}
            onChange={handleChangeSite}
            SelectProps={{
              native: true,
            }}
          >
            <option value="">Select Organization</option>
            {orgs.map((org) => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))}
          </TextField>
          <TextField
            margin="dense"
            name="meta"
            label="Metadata"
            type="text"
            fullWidth
            value={metaFile}
            onChange={handleChangeSite}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSiteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitSite} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
        </div>
    )
}

export default Org_Site_Listpage