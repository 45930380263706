import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../context/UserContext'
import { Divider } from '@mui/material';
import '../styles/__Fetchfiles.css'
import Cookies from 'js-cookie';

const Fetch_Files = () => {
  const [filesList,setfilesList] = useState<any[]>([]);
  const {ActiveSiteId, setActiveSiteId, changeSite, ActiveOrgId} = useContext(UserContext)
  const [modalOpen, setModalOpen] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File[]>([]) 
  const csrfToken = Cookies.get('csrftoken'); 
  let file: any;
  const [isUpLoading, setIsUpLoading] = useState(false)
  const hiddenAnchorRef = React.useRef<HTMLAnchorElement | null>(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  
  
  const renderFileIcon = (fileExtension: string): JSX.Element | null => {
    if (fileExtension === 'pdf') {
      return <img src={require('../assets/PDF_file_icon.svg.png')} alt="pdf_icon" className='filelisticon' />;
    } else if (fileExtension === 'xlsx') {
      return <img src={require('../assets/excel.png')} alt="csv_icon" className='csvfilelisticon' />;
    }
   
    return null;
  };

  const getFileExtension = (filename: any)  => {
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
  };


  const [downloadUrl, setDownloadUrl] = useState<string>('');
  const handleDownload = async(fileName: string) => {
    const ActiveSiteId = sessionStorage.getItem('ActiveSiteId')
    const ActiveOrgId = sessionStorage.getItem('ActiveOrgId')
    const file_name = fileName.split('/').pop();
    const url = 'api/download/'+ActiveOrgId+'/'+ActiveSiteId+'/'+file_name+'/'
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setDownloadUrl(data.file_url);
    
    if (hiddenAnchorRef.current) {
      hiddenAnchorRef.current.href = data.file_url;
      hiddenAnchorRef.current.download = fileName;
      hiddenAnchorRef.current.click();
            }
   };

  const handlefileslist= async() => {
    const ActiveSiteId = sessionStorage.getItem('ActiveSiteId')
    const ActiveOrgId = sessionStorage.getItem('ActiveOrgId')
    if (ActiveSiteId) 
    {
    
      const url = 'api/'+ActiveOrgId+'/'+ActiveSiteId+'/files/'
      setActiveSiteId(ActiveSiteId)
    try
      {
          const response = await fetch(url)
          if (response.ok)
          {   
              const list = await response.json()
              setfilesList(list)      
                      
          }
          else{
              console.error("File Not Found:")
          }
      }
    catch(error)
      {
          console.error('Error fetching data:', error);
      }
    }
    else{
        console.log("No Site is selected. Select the site")
    }
  }


  useEffect(() => {
    handlefileslist()
  }, []);


  const handleFileSelect = (event:any) => {
    if (event.target.files){
        
        setFileToUpload(event.target.files)    
        setUploadSuccess(false)
    }
}



const handleCloseModal = () => {
  setModalOpen(false);
  setIsUpLoading(false);
  setUploadSuccess(false);
};

const handleSubmit = async () => {
  const ActiveOrgId = sessionStorage.getItem('ActiveOrgId')
  const url = 'api/'+ActiveOrgId+'/'+ActiveSiteId+'/file_upload/'
  if (fileToUpload)
  {
      for (file of fileToUpload){
          const formData = new FormData();
          formData.append('myfile', file);
      
      try {
          const response = await fetch(url, {
          method: 'POST',
          body: formData,
          });
          
          if (response.ok) {
          setUploadSuccess(true); 
          handlefileslist()
          } else {
          console.error('Upload failed.');
          }
      } catch (error) {
          console.error('An error occurred:', error);
      }
      }
  }
}



const handleDelete= async (filename:string) => {
  const ActiveSiteId = sessionStorage.getItem('ActiveSiteId')
  const ActiveOrgId = sessionStorage.getItem('ActiveOrgId')
  const fileName = filename.split('/').pop();
  // const headers = new Headers();
  // console.log(csrfToken)
  // headers.append('Content-Type', 'application/json',);
  // headers.append('X-CSRFToken', csrfToken || '');
  
  // const url = 'api/'+filename
  // console.log(url)
  const url = 'api/'+ActiveOrgId+'/'+ActiveSiteId+'/chat/'+fileName
    try
    {
      const response = await fetch(url, {
      method: 'POST',
      
      body: JSON.stringify({
        filename:filename,
      })
      
    });
        if (response.ok)
        {
          handlefileslist()
        }
        else{
            console.error("File Not Found:")
        }
    }
    catch(error)
    {
        console.error('Error fetching data:', error);
    }
  }

  return (
    <div>
    <div id="Files">
      
      <div className='file-list-container'>
      
            <div className='Files_heading'>Files Available</div>
            <Divider className='divider'/>
            
            
            <div className='header-container'>
                  <button  className='Upload_file_button button2' onClick={() => setModalOpen(!modalOpen)}>+</button>
            </div>
            


            <ol className='ollist' >
            {filesList.map((file, index) => (
                <div className='filelistbox' key={index}>
                    
                  <li className='filelist' >
                    {renderFileIcon(getFileExtension(file.file_name))}
                    <div className="file-name-container">
                      <span className="file-name">{file.file_name}</span>
                    </div>

                    <img 
                      src={require('../assets/icons8-download-48.png')} 
                      alt="download_icon" 
                      className='deleteicon' 
                      onClick={() => handleDownload(file.file_name)} 
                    />
                      <a 
                      href={downloadUrl} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      ref={hiddenAnchorRef}
                      style={{ display: 'none' }}
                      >Download File</a>
            
                    <img src={require('../assets/delete (2).png')} alt="delete_icon" className='deleteicon' onClick={() => handleDelete(file.file_name)}/>
                </li>
                
                </div>
            ))}
            </ol>

            
            
            <div className="container">
      {modalOpen && (
        <div className="card">
          <button className="close-btn" onClick={handleCloseModal}>X</button>
          <input
            className="file-upload-card"
            placeholder="Choose File"
            type="file"
            onChange={handleFileSelect}
            disabled={ActiveSiteId === 0}
            multiple
          />

          <div className="upload-button-container">
            <button
              className="upload-btn"
              onClick={handleSubmit}
              disabled={isUpLoading || !fileToUpload}
            >
              {isUpLoading ? 'Uploading ...' : 'Upload'}
            </button>
          </div>
          {uploadSuccess && (
          <div className="success-message">
            File successfully uploaded!
          </div>
        )}
        </div>
      )}
    </div>
  </div>
  </div>
  </div>
      
  )
}

export default Fetch_Files
